export const transformMenuItems = menuItems => {
  const menuMap = {}

  // Crear un mapa de los elementos del menú
  menuItems.forEach(item => {
    item.childItems = { edges: [] }
    menuMap[item.databaseId] = item
  })

  // Asignar los elementos hijos a sus padres
  menuItems.forEach(item => {
    if (item.parentDatabaseId !== 0) {
      const parent = menuMap[item.parentDatabaseId]
      if (parent) {
        parent.childItems.edges.push({ node: item })
      }
    }
  })

  // Filtrar los elementos que no tienen padres
  const transformedMenuItems = menuItems.filter(
    item => item.parentDatabaseId === 0
  )

  // Eliminar la propiedad parentDatabaseId y databaseId
  transformedMenuItems.forEach(item => {
    delete item.parentDatabaseId
    delete item.databaseId
    item.childItems.edges.forEach(edge => {
      delete edge.node.parentDatabaseId
      delete edge.node.databaseId
    })
  })

  return transformedMenuItems
}
